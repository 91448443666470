<template>
  <div>
    <ejs-dialog
      ref="groupManagementRegistrationPopup"
      header="단체정보 상세"
      :animationSettings="animationSettings"
      :allowDragging="true"
      :showCloseIcon="true"
      :isModal="true"
      width="1000"
      :cssClass="
        this.grpNo
          ? $t('className.popup.productCodePopupModify')
          : $t('className.popup.productCodePopupAdd')
      "
      :close="onGroupDialogCloseClicked"
    >
      <div class="window groupInformation">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">단체정보 상세</div>
                    </div>
                  </div>
                  <div class="section-body custom-section-body">
                    <article class="body-article">
                      <!-- 아코디언 : accordion / 닫힘 : close -->
                      <section class="article-section section-0101">
                        <div class="section-header">
                          <div class="header-left">
                            <div class="header-title">단체 정보</div>
                          </div>
                        </div>
                        <div class="section-body">
                          <div class="body-data">
                            <div class="data-outer">
                              <div class="data-inner">
                                <ul class="data-content">
                                  <li class="field">
                                    <!-- 필수 : required -->
                                    <div class="title required">단체종류</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <ejs-dropdownlist
                                                ref="grpKind"
                                                v-model="groupInfo.grpKind"
                                                :dataSource="groupInfoOption.grpKind"
                                                :fields="commonCodeFields"
                                                :allowFiltering="false"
                                                @keydown.native="preventEnterEvent"
                                                cssClass="body-data-dropdown"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <!-- 필수 : required -->
                                    <div class="title required">단체구분</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <ejs-dropdownlist
                                                ref="grpDiv"
                                                v-model="groupInfo.grpDiv"
                                                :dataSource="groupInfoOption.grpDiv"
                                                :fields="commonCodeFields"
                                                :allowFiltering="false"
                                                @keydown.native="preventEnterEvent"
                                                cssClass="body-data-dropdown"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field grpNo">
                                    <!-- 필수 : required -->
                                    <div class="title">단체번호</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group input" style="width: 80px">
                                          <div v-if="grpNo">
                                            {{ groupInfo.grpNo }}
                                          </div>
                                          <div v-else>
                                            <div class="form">
                                              <input-text
                                                  id="grpNo"
                                                  v-model="groupInfo.grpNo"
                                                  :maxlength="10"
                                                  :disabled="isAutoAssignmentGrpNo"
                                                  @keydown.native="preventEnterEvent"
                                              />
                                            </div>
                                          </div>
                                        </li>
                                        <li
                                            class="item form-group check"
                                            v-if="!grpNo"
                                        >
                                          <!-- 포커스 : focus -->
                                          <ul class="check">
                                            <li>
                                              <label>
                                                <input
                                                    type="checkbox"
                                                    v-model="isAutoAssignmentGrpNo"
                                                    @change="
                                            onIsAutoAssignmentGrpNoChange
                                          "
                                                />
                                                <i></i>
                                                <div class="label">자동</div>
                                              </label>
                                            </li>
                                          </ul>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <!-- 필수 : required -->
                                    <div class="title required">단체명</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-text
                                                ref="grpName"
                                                v-model="groupInfo.grpName"
                                                @keydown.native="preventEnterEvent"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <!-- 필수 : required -->
                                    <div class="title">등록일자</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-date
                                                :format="DATE_FORMAT_YYYY_MM_DD"
                                                v-model="groupInfo.insertDate"
                                                type="body-data"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <!-- 필수 : required -->
                                    <div class="title">사용여부</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <ul class="check">
                                            <li>
                                              <label>
                                                <input
                                                    type="radio"
                                                    id="useFlagTrue"
                                                    :value="true"
                                                    v-model="groupInfo.useFlag"
                                                    name="useFlag"
                                                />
                                                <i></i>
                                                <div class="label">사용</div>
                                              </label>
                                            </li>
                                            <li>
                                              <label>
                                                <input
                                                    type="radio"
                                                    id="useFlagFalse"
                                                    :value="false"
                                                    v-model="groupInfo.useFlag"
                                                    name="useFlag"
                                                />
                                                <i></i>
                                                <div class="label">미사용</div>
                                              </label>
                                            </li>
                                          </ul>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <!-- 필수 : required -->
                                    <div class="title">시작월</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <ejs-dropdownlist
                                                id="resveCycle"
                                                v-model="groupInfo.resveMm"
                                                :dataSource="groupInfoOption.resveMm"
                                                :fields="commonCodeFields"
                                                :allowFiltering="false"
                                                @keydown.native="preventEnterEvent"
                                                cssClass="body-data-dropdown"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <!-- 필수 : required -->
                                    <div class="title">예약주기</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <ejs-dropdownlist
                                                id="resveCycle"
                                                v-model="groupInfo.resveCycle"
                                                :dataSource="groupInfoOption.resveCycle"
                                                :fields="commonCodeFields"
                                                :allowFiltering="false"
                                                @keydown.native="preventEnterEvent"
                                                cssClass="body-data-dropdown"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <!-- 필수 : required -->
                                    <div class="title">요일</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <ejs-dropdownlist
                                                id="dwCode"
                                                v-model="groupInfo.dwCode"
                                                :dataSource="groupInfoOption.dwCode"
                                                :fields="commonCodeFields"
                                                :allowFiltering="false"
                                                @keydown.native="preventEnterEvent"
                                                cssClass="body-data-dropdown"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <!-- 필수 : required -->
                                    <div class="title">희망시간</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <ejs-dropdownlist
                                                id="grpHopeTime"
                                                v-model="groupInfo.grpHopeTime"
                                                :dataSource="groupInfoOption.grpHopeTime"
                                                :fields="commonCodeFields"
                                                :allowFiltering="false"
                                                @keydown.native="preventEnterEvent"
                                                cssClass="body-data-dropdown"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <!-- 필수 : required -->
                                    <div class="title">신청팀수</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-number
                                                id="teamCnt"
                                                v-model="groupInfo.teamCnt"
                                                :min="0"
                                                @keydown.native="preventEnterEvent"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <!-- 필수 : required -->
                                    <div class="title">지역</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-text
                                                id="areaCode"
                                                ref="areaCode"
                                                v-model="groupInfo.areaCode"
                                                :maxlength="20"
                                                @keydown.native="preventEnterEvent"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <!-- 필수 : required -->
                                    <div class="title required">예약채널</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <ejs-dropdownlist
                                                id="resveChannel"
                                                ref="resveChannel"
                                                v-model="groupInfo.resveChannel"
                                                :dataSource="groupInfoOption.resveChannel"
                                                :fields="commonCodeFields"
                                                :allowFiltering="false"
                                                @keydown.native="preventEnterEvent"
                                                cssClass="body-data-dropdown"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <!-- 필수 : required -->
                                    <div class="title">요금코드</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <ejs-dropdownlist
                                                id="paymtCode"
                                                v-model="groupInfo.paymtCode"
                                                :dataSource="groupInfoOption.paymtCode"
                                                :fields="commonCodeFields"
                                                :allowFiltering="false"
                                                @keydown.native="preventEnterEvent"
                                                cssClass="body-data-dropdown"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field discountCode">
                                    <!-- 필수 : required -->
                                    <div class="title">할인코드</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group input">
                                          <div class="form">
                                            <input-text
                                                id="dcId"
                                                v-model.trim="groupInfo.dcName"
                                                @change="
                                        onDcNameChanged(
                                          $event,
                                          groupInfo.dcName
                                        )
                                      "
                                                @keydown.native="preventEnterEvent"
                                            />
                                          </div>
                                        </li>
                                        <li class="item form-group button">
                                          <ul class="button">
                                            <li class="search">
                                              <erp-button
                                                  button-div="GET"
                                                  @click.native="onDcIdSearchPopupOpen"
                                              >
                                              </erp-button>
                                            </li>
                                          </ul>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                </ul>
                                <div class="border-left"></div>
                                <div class="border-right"></div>
                                <div class="border-top"></div>
                                <div class="border-bottom"></div>
                              </div>
                            </div>
                            <div class="border-left"></div>
                            <div class="border-right"></div>
                            <div class="border-top"></div>
                            <div class="border-bottom"></div>
                          </div>
                        </div>
                      </section>
                      <!-- 아코디언 : accordion / 닫힘 : close -->
                      <section class="article-section section-0102">
                        <div class="section-header">
                          <div class="header-left">
                            <div class="header-title">총무/회장</div>
                          </div>
                        </div>
                        <div class="section-body">
                          <div class="body-data">
                            <div class="data-outer">
                              <div class="data-inner">
                                <ul class="data-content">
                                  <li class="field generalAffairsName">
                                    <!-- 필수 : required -->
                                    <div class="title">총무</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group input">
                                          <div class="form">
                                            <input-text
                                                id="generName"
                                                @change="
                                        onNameChanged($event, 'generName')
                                      "
                                                v-model.trim="groupInfo.generName"
                                            />
                                          </div>
                                        </li>
                                        <li class="item form-group button">
                                          <ul class="button">
                                            <li class="search">
                                              <erp-button
                                                button-div="GET"
                                                @click.native="
                                                  onMemberPopupOpen(
                                                    true,
                                                    memberPopupType.GENER,
                                                    groupInfo.generName
                                                  )
                                                "
                                              >
                                                검색
                                              </erp-button>
                                            </li>
                                          </ul>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <!-- 필수 : required -->
                                    <div class="title">연락처</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <component-telephone
                                                id="generContactTel"
                                                v-model="groupInfo.generContactTel"
                                                :max-length="11"
                                                @keydown.native="preventEnterEvent"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <!-- 필수 : required -->
                                    <div class="title">회원구분</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          {{
                                            commonCodesGetComName(
                                                "MEMBER_DIV",
                                                groupInfo.generMemberDiv
                                            )
                                          }}
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field chairmanName">
                                    <!-- 필수 : required -->
                                    <div class="title">회장</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group input">
                                          <div class="form">
                                            <input-text
                                                id="chairName"
                                                @change="
                                        onNameChanged($event, 'chairName')
                                      "
                                                v-model.trim="groupInfo.chairName"
                                            />
                                          </div>
                                        </li>
                                        <li class="item form-group button">
                                          <ul class="button">
                                            <li class="search">
                                              <erp-button
                                                button-div="GET"
                                                @click.native="
                                                  onMemberPopupOpen(
                                                    true,
                                                    memberPopupType.CHAIR,
                                                    groupInfo.chairName
                                                  )
                                                "
                                              >
                                                검색
                                              </erp-button>
                                            </li>
                                          </ul>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <!-- 필수 : required -->
                                    <div class="title">연락처</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <component-telephone
                                                id="chairContactTel"
                                                v-model="groupInfo.chairContactTel"
                                                :max-length="11"
                                                @keydown.native="preventEnterEvent"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <!-- 필수 : required -->
                                    <div class="title">회원구분</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          {{
                                            commonCodesGetComName(
                                                "MEMBER_DIV",
                                                groupInfo.chairMemberDiv
                                            )
                                          }}
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
<!--                                    <li class="field">-->
<!--                                      <div class="content" style="width: 100%;"></div>-->
<!--                                    </li>-->
                                </ul>
                                <div class="border-left"></div>
                                <div class="border-right"></div>
                                <div class="border-top"></div>
                                <div class="border-bottom"></div>
                              </div>
                            </div>
                            <div class="border-left"></div>
                            <div class="border-right"></div>
                            <div class="border-top"></div>
                            <div class="border-bottom"></div>
                          </div>
                        </div>
                      </section>
                      <!-- 아코디언 : accordion / 닫힘 : close -->
                      <section class="article-section section-0102">
                        <div class="section-header">
                          <div class="header-left">
                            <div class="header-title">회원</div>
                          </div>
                          <div class="header-right">
                            <ul class="header-button">
                              <li class="add">
                                <erp-button
                                    button-div="SAVE"
                                    :is-icon-custom="true"
                                    @click.native="onAddGrpMemberInfoClicked"
                                >
                                  추가
                                </erp-button>
                              </li>
                              <li class="delete">
                                <erp-button
                                    button-div="DELETE"
                                    :is-icon-custom="true"
                                    @click.native="onDeleteGrpMemberInfoClicked"
                                >
                                  삭제
                                </erp-button>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div
                          class="section-body"
                          @click.capture="onGrpMembersGridClick"
                        >
                          <ejs-grid-wrapper
                              ref="grpMembersGrid"
                              :provides="grpMembersGridProvides"
                              :dataSource="groupInfo.grpMemberList"
                              :columns="grpMembersGridColumns"
                              :height="96"
                              :enableHover="false"
                              :allowSorting="false"
                              :allowFiltering="false"
                              :validationRules="grpMembersGridValidationRules"
                              :validationModification="false"
                              :selectionSettings="grpMembersGridSelectionSettings"
                              :noColumnWidth="40"
                              @headerCellInfo="grpMembersGridHeaderCellInfo"
                              @queryCellInfo="grpMembersGridQueryCellInfo"
                              @cellSave="grpMembersGridCellSave($event)"
                              @cellSaved="grpMembersGridCellSaved($event)"
                              @recordClick="grpMembersGridClicked"
                              @rowSelected="onGrpMemberGridRowSelected"
                              @keyPressed="onKeyPressed($event)"
                          >
                          </ejs-grid-wrapper>
                        </div>
                      </section>
                      <!-- 아코디언 : accordion / 닫힘 : close -->
                      <section
                        :class="[
                          'article-section',
                          'section-0103',
                          'accordion',
                          { close: !visibleDpmnyInputs },
                        ]"
                      >
                        <div class="section-header">
                          <div class="header-left">
                            <div
                              class="header-title"
                              @click="
                                visibleDpmnyInputs = !visibleDpmnyInputs
                              "
                            >
                              예치금
                            </div>
                          </div>
                        </div>
                        <div class="section-body">
                          <div class="body-data">
                            <div class="data-outer">
                              <div class="data-inner">
                                <ul class="data-content">
                                  <li class="field">
                                    <!-- 필수 : required -->
                                    <div class="title">입금구분</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <ejs-dropdownlist
                                                id="dwCode"
                                                v-model="groupInfo.dpmnyIncomDiv"
                                                :dataSource="commonCodesGetCommonCode('INCOM_DIV')"
                                                :fields="commonCodeFields"
                                                :allowFiltering="false"
                                                @keydown.native="preventEnterEvent"
                                                cssClass="body-data-dropdown"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <!-- 필수 : required -->
                                    <div class="title">예치금액</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-number
                                                id="dpmnyAmt"
                                                ref="dpmnyAmt"
                                                v-model="groupInfo.dpmnyAmt"
                                                :propMaxLength="9"
                                                @keydown.native="preventEnterEvent"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <!-- 필수 : required -->
                                    <div class="title">예금주명</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-text
                                                id="dpmnyDpstrName"
                                                ref="dpmnyDpstrName"
                                                v-model="groupInfo.dpmnyDpstrName"
                                                :maxlength="20"
                                                @keydown.native="preventEnterEvent"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <!-- 필수 : required -->
                                    <div class="title">입금일자/확인</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group" style="width: calc(100% - 23px);">
                                          <div class="form">
                                            <input-date
                                                :format="DATE_FORMAT_YYYY_MM_DD"
                                                v-model="groupInfo.dpmnyIncomDate"
                                                type="body-data"
                                            />
                                          </div>
                                        </li>
                                        <li class="item form-group check" style="width: 23px;">
                                          <ul class="check">
                                            <li>
                                              <label>
                                                <input
                                                    type="checkbox"
                                                    v-model="groupInfo.dpmnyIncomConfirm"
                                                />
                                                <i></i>
                                              </label>
                                            </li>
                                          </ul>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <!-- 필수 : required -->
                                    <div class="title">반환일자/확인</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group" style="width: calc(100% - 23px);">
                                          <div class="form" style="width: calc(100% + 1px)">
                                            <input-date
                                                :format="DATE_FORMAT_YYYY_MM_DD"
                                                v-model="groupInfo.dpmnyReturnDate"
                                                type="body-data"
                                            />
                                          </div>
                                        </li>
                                        <li class="item form-group check" style="width: 23px;">
                                          <ul class="check">
                                            <li>
                                              <label>
                                                <input
                                                    type="checkbox"
                                                    v-model="groupInfo.dpmnyReturnConfirm"
                                                />
                                                <i></i>
                                              </label>
                                            </li>
                                          </ul>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                </ul>
                                <div class="border-left"></div>
                                <div class="border-right"></div>
                                <div class="border-top"></div>
                                <div class="border-bottom"></div>
                              </div>
                            </div>
                            <div class="border-left"></div>
                            <div class="border-right"></div>
                            <div class="border-top"></div>
                            <div class="border-bottom"></div>
                          </div>
                        </div>
                      </section>
                      <!-- 아코디언 : accordion / 닫힘 : close -->
                      <section class="article-section section-0104">
                        <div class="section-header">
                          <div class="header-left">
                            <div class="header-title">비고 사항</div>
                          </div>
                        </div>
                        <div class="section-body">
                          <div class="body-data">
                            <div class="data-outer">
                              <div class="data-inner">
                                <ul class="data-content">
                                  <li class="field remarks">
                                    <!-- 필수 : required -->
                                    <div class="title">계약조건</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-textarea
                                                id="resveRemarks"
                                                v-model="groupInfo.resveRemarks"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field remarks">
                                    <!-- 필수 : required -->
                                    <div class="title">결제조건</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-textarea
                                                id="frontRemarks"
                                                v-model="groupInfo.frontRemarks"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field remarks">
                                    <!-- 필수 : required -->
                                    <div class="title">비고</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-textarea
                                                id="grpCharct"
                                                v-model="groupInfo.grpCharct"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                </ul>
                                <div class="border-left"></div>
                                <div class="border-right"></div>
                                <div class="border-top"></div>
                                <div class="border-bottom"></div>
                              </div>
                            </div>
                            <div class="border-left"></div>
                            <div class="border-right"></div>
                            <div class="border-top"></div>
                            <div class="border-bottom"></div>
                          </div>
                        </div>
                      </section>
                      <!-- 아코디언 : accordion / 닫힘 : close -->
                      <section class="article-section section-0105">
                        <div class="section-header">
                          <div class="header-left">
                            <div class="header-title">
                              예약월/시간
                            </div>
                          </div>
                        </div>
                        <div class="section-body">
                          <div class="body-data">
                            <div class="data-outer">
                              <div class="data-inner">
                                <ul class="data-content">
                                  <li class="field reservationMonthTime">
                                    <!-- 필수 : required -->
                                    <div class="content title">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <ul class="check">
                                            <li>
                                              <label>
                                                <input
                                                    type="checkbox"
                                                    value="true"
                                                    @change="resveMtAllCheck"
                                                    v-model="resveMtValueCheckAll"
                                                />
                                                <i></i>
                                                <div class="label">전체</div>
                                              </label>
                                            </li>
                                          </ul>
                                        </li>
                                      </ul>
                                    </div>
                                    <div class="content">
                                      <ul class="row">
                                        <li
                                            class="item form-group"
                                            v-for="(data, idx) in monthList"
                                            :key="idx"
                                        >
                                          <ul class="check">
                                            <li>
                                              <label>
                                                <input
                                                    type="checkbox"
                                                    v-model="data.isCheck"
                                                    @change="resveMtCheck($event, idx)"
                                                />
                                                <i></i>
                                                <div class="label">
                                                  {{ data.month }}월
                                                </div>
                                              </label>
                                            </li>
                                          </ul>
                                        </li>
                                      </ul>
                                      <ul class="row">
                                        <li
                                            class="item form-group"
                                            v-for="(data, idx) in monthList"
                                            :key="idx"
                                        >
                                          <div class="form" v-if="data.isCheck">
                                            <ejs-maskedtextbox
                                                v-model="data.resveTime"
                                                mask="00:00"
                                                @keydown.native="preventEnterEvent"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                </ul>
                                <div class="border-left"></div>
                                <div class="border-right"></div>
                                <div class="border-top"></div>
                                <div class="border-bottom"></div>
                              </div>
                            </div>
                            <div class="border-left"></div>
                            <div class="border-right"></div>
                            <div class="border-top"></div>
                            <div class="border-bottom"></div>
                          </div>
                        </div>
                      </section>
                    </article>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <div class="lookup-condition">
            <li class="field">
              <ul class="button">
                <li>
                  <erp-button
                      button-div="GET"
                      :is-icon-custom="true"
                      @click.native="onChangeHistoryButtonClicked"
                  >
                    변경이력
                  </erp-button>
                </li>
              </ul>
            </li>
          </div>
          <ul class="button">
            <li class="save keyColor">
              <erp-button
                  button-div="SAVE"
                  :ignore="isPopupOpened"
                  :is-key-color="true"
                  :is-shortcut-button="true"
                  v-on:click.native="onGroupSaveClicked"
              >
                저장
              </erp-button>
            </li>
            <li class="delete" v-if="this.grpNo">
              <erp-button
                  button-div="DELETE"
                  :ignore="isPopupOpened"
                  :is-shortcut-button="true"
                  @click.native="onGroupDeleteClicked"
              >
                삭제
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="onGroupCloseClicked">
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
    <search-normal-dc-popup
      v-if="isDcIdSearchPopupOpen"
      ref="searchNormalDcPopup"
      @popupClosed="searchNormalDcPopupClosed"
    />
    <member-select-popup
      v-if="isMemberSelectPopupOpen"
      ref="memberSelectPopup"
      @popupClosed="memberSelectPopupClosed"
      @popupConfirmed="memberSelectPopupConfirmed"
    />
    <history-popup
      v-if="isHistoryPopupOpen"
      ref="historyPopup"
      @popupClosed="historyPopupClose"
    />
  </div>
</template>

<script>
import {formNotPassedRequiredCheck, formPreventEnterEvent, ValidType} from "@/utils/formUtil";
import {
  putGroupDetail,
  postGroupDetail,
  deleteGroupDetail,
  getGroupInfoDetail,
} from "@/api/group";
import {
  commonCodeGetComCodeDefaultValue,
  commonCodesGetCommonCode,
  commonCodesGetComName,
  // commonCodeGetComCodeDefaultValue,
} from "@/utils/commonCodes";
import { getDcCodeList } from "@/api/frontManagementCheckin";
import { validateFormRefs } from "@/utils/formUtil";
import { DATE_FORMAT_YYYY_MM_DD } from "@/utils/date";
import commonMixin from "@/views/layout/mixin/commonMixin";

import searchNormalDcPopup from "@/views/common/SearchNormalDcPopup";
import memberSelectPopup from "@/views/common/MemberSelectPopup";
import InputDate from "@/components/common/datetime/InputDate";
import InputNumber from "@/components/common/InputNumber";
import InputText from "@/components/common/text/InputText";
import InputTextarea from "@/components/common/text/InputTextarea";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";

import ComponentTelephone from "@/components/ComponentTelephone";
import GolfERPService from '@/service/GolfERPService';
import moment from 'moment';
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import HistoryPopup from "@/views/common/HistoryPopup";
import ErpButton from "@/components/button/ErpButton.vue";
import ejsGridWrapper from "@/components/common/EjsGridWrapper.vue";
import {Aggregate, Edit, ForeignKey, Group, Resize} from "@syncfusion/ej2-vue-grids";
import Vue from "vue";
import gridVisitEjsDropdownlistEditTemplate
  from "@/components/common/gridTemplate/GridVisitEjsDropdownlistEditTemplate";
import {gridUtilGetTelColumnAccess} from "@/utils/gridUtil";

export default {
  name: "groupManagementRegistrationPopup",
  components: {
    ejsGridWrapper,
    ComponentTelephone,
    InputTextarea,
    InputText,
    InputNumber,
    searchNormalDcPopup,
    memberSelectPopup,
    InputDate,
    HistoryPopup,
    ErpButton,
  },
  mixins: [commonMixin, confirmDialogMixin],
  async created() {
    const nowMoment = await GolfERPService.fetchNow();
    this.groupInfo.insertDate = moment(nowMoment.toDate()).format(DATE_FORMAT_YYYY_MM_DD);
  },
  mounted() {
    this.monthList = [];
    for (let i = 1; i <= 12; i++) {
      this.monthList.push({
        month: i,
        grpNo: null,
        resveMt: null,
        resveTime: null,
        isCheck: false,
      });
    }
  },
  computed: {
    isPopupOpened() {
      return this.isDcIdSearchPopupOpen || this.isMemberSelectPopupOpen;
    },
  },
  data() {
    return {
      DATE_FORMAT_YYYY_MM_DD,
      preventEnterEvent: formPreventEnterEvent,
      commonCodeFields: { text: "comName", value: "comCode" },
      grpNo: null,
      monthList: [],
      validateRefList: {
        grpKind: {
          required: true,
        },
        grpName: {
          required: true,
        },
        resveChannel: {
          required: true,
        },
        grpDiv:{
          required: true,
        }
      },
      groupInfoDetailForm: {},
      groupRegistrationForm: {},
      memberPopupType: {
        GENER: "GENER", // 총무
        CHAIR: "CHAIR", // 회장
        MEMBER: "회원", // 회원
      },
      isMemberSelectPopupOpen: false,
      isReservationInfoPopupOpen: false,
      isDcIdSearchPopupOpen: false,
      groupInfo: {
        grpNo: null, // 단체번호
        grpDiv:null,  //단체구분
        grpKind: null, // 단체종류
        grpName: null, // 단체명
        teamCnt: null, // 팀수
        resveChannel: null, // 예약채널
        areaCode: null, // 지역코드
        insertDate: null, // 등록일자
        resveMm:null, // 시작월
        resveCycle: null, // 예약주기
        paymtCode: null, // 요금코드
        grpHopeTime:null,  //희망시간
        dwCode: null, // 요일코드
        dcId: null, // 할인ID
        dcName: null, // 할인ID_내용_그리드 출력용
        generName: null, // 총무명
        generMembershipId: null, // 총무회원
        generMemberDiv: null, // 총무회원ID
        generContactTel: null, // 총무연락처
        chairName: null, // 회장명
        chairMemberDiv: null, // 회장회원
        chairMembershipId: null, // 회장회원ID
        chairContactTel: null, // 회장연락처
        dpmnyIncomDate: null, // 입금일자
        dpmnyIncomConfirm: false, // 입금확인
        dpmnyReturnDate: null, // 반환일자
        dpmnyReturnConfirm: false, // 반환확인
        grpCharct: null, // 단체비고
        resveRemarks: null, // 계약조건
        frontRemarks: null, // 결제조건
        useFlag: true, // 사용여부
        groupMonthTimeList: [
          {
            grpNo: null, // 단체번호
            resveMt: null, // 예약월
            resveTime: null, // 예약시간
          },
        ],
      },
      animationSettings: { effect: "None" },
      resveMtValueCheckAll: false,
      refresh: false,
      isAutoAssignmentGrpNo: true,
      groupInfoOption: {
        grpHopeTime: commonCodesGetCommonCode("GRP_HOPE_TIME",true),
        resveChannel: commonCodesGetCommonCode("RESVE_CHANNEL", true),
        grpKind: commonCodesGetCommonCode("GRP_KIND", true),
        grpDiv: commonCodesGetCommonCode("GRP_DIV", true),
        resveMm: commonCodesGetCommonCode("RESVE_MM", true),
        resveCycle: commonCodesGetCommonCode("RESVE_CYCLE", true),
        dwCode: commonCodesGetCommonCode("DW_CODE", true),
        paymtCode: commonCodesGetCommonCode("PAYMT_CODE", true),
      },
      isHistoryPopupOpen: false,
      visibleDpmnyInputs: false,
      visitsGridSelectionSettings: {
        type: "Single",
        mode: "Both",
        enableToggle: false,
      },
      grpMembersGridProvides: [Edit, Resize, ForeignKey, Aggregate, Group],
      gridGrpMembersEjsDropdownlistEditTemplateEventBus: new Vue(),
      grpMembersGridSelectedIdx: -1,
      grpMembersGridColumns: [
        {
          field: "grpMemberId",
          visible: false,
        },
        {
          field: "memberName",
          headerText: "이름",
          allowEditing: true,
          minWidth: 16,
          width: 60,
          type: "string",
          textAlign: "center",
        },
        {
          field: "memberNameIcon",
          allowEditing: false,
          headerText: "",
          allowFiltering: false,
          minWidth: 16,
          width: 32,
          type: "string",
        },
        {
          field: "hpNo",
          headerText: "연락처",
          allowEditing: true,
          minWidth: 16,
          width: 110,
          type: "string",
          textAlign: "center",
          editType: "telephoneedit",
          valueAccessor: (field, data) =>
              gridUtilGetTelColumnAccess(field, data),
        },
        {
          field: "smsRecptnFlag",
          headerText: "예약알림수신여부",
          minWidth: 16,
          width: 90,
          type: "boolean",
          editType: "booleanedit",
          displayAsCheckBox: true,
          textAlign: "Center",
        },
        {
          field: "sexCode",
          headerText: "성별",
          minWidth: 16,
          width: 50,
          type: "string",
          textAlign: "center",
          editTemplate: () =>
              gridVisitEjsDropdownlistEditTemplate(
                  "sexCode",
                  commonCodesGetCommonCode("SEX_CODE", true),
                  this.$refs.grpMembersGrid,
                  this.gridGrpMembersEjsDropdownlistEditTemplateEventBus
              ),
          valueAccessor: (field, data) => {
            const commonCode = commonCodesGetCommonCode("SEX_CODE").find(
                (commonCode) => commonCode.comCode === data[field]
            );
            if (commonCode) {
              return commonCode.comName;
            } else {
              return null;
            }
          },
        },
        {
          field: "memberNo",
          headerText: "회원번호",
          allowEditing: false,
          minWidth: 16,
          width: 90,
          type: "string",
          textAlign: "center",
        },
        {
          field: "memberDiv",
          headerText: "회원구분",
          allowEditing: false,
          minWidth: 16,
          width: 90,
          type: "string",
          textAlign: "center",
          isCommonCodeField: true,
          groupCode: "MEMBER_DIV",
        },
        {
          field: "remarks",
          headerText: "비고",
          allowEditing: true,
          minWidth: 16,
          width: 90,
          type: "string",
          textAlign: "left",
        },
        {
          field: "membershipId",
          type: "string",
          visible: false,
        },
      ],
      grpMembersGridValidationRules: {
        memberName: {
          required: true,
          maxLength: 50,
        },
        hpNo: {
          type: ValidType.HP,
          custom: {
            method: (data) => {
              let rtn = false;

              if (data.hpNo === undefined || data.hpNo === null || data.hpNo === "" ) {
                rtn = true;
              } else {
                rtn = !formNotPassedRequiredCheck(data.hpNo);
              }

              return rtn;
            },
            message: this.$t("main.validationMessage.requiredMessage", ["단체 회원 연락처"])
          }
        }
      },
      grpMembersGridSelectionSettings: {
        type: "Single",
        mode: "Both",
        enableToggle: false,
      },
    };
  },
  methods: {
    commonCodesGetCommonCode,
    validateFormRefs,
    commonCodesGetComName: commonCodesGetComName,
    resveMtAllCheck(args) {
      if (args.target.checked) {
        for (let i = 0; i < 12; i++) {
          if (!this.monthList[i].isCheck) {
            this.monthList[i].resveMt = this.monthList[i].month;
            this.monthList[i].resveTime = null;
            this.monthList[i].isCheck = true;
          }
        }
      } else {
        for (let i = 0; i < 12; i++) {
          this.monthList[i].grpNo = null;
          this.monthList[i].resveMt = null;
          this.monthList[i].resveTime = null;
          this.monthList[i].isCheck = false;
        }
      }
    },
    resveMtCheck(event, idx) {
      this.monthList[idx].resveMt = this.monthList[idx].month;
      this.monthList[idx].resveTime = null;

      let checkCount = 12;
      for (let i = 0; i < 12; i++) {
        if (this.monthList[i].isCheck) {
          checkCount++;
        }
        checkCount--;
      }

      if (checkCount === 12) {
        this.resveMtValueCheckAll = true;
      } else {
        this.resveMtValueCheckAll = false;
      }

      if (!this.monthList[idx].isCheck) {
        this.monthList[idx].grpNo = null;
        this.monthList[idx].resveMt = null;
        this.monthList[idx].resveTime = null;
      }
    },
    onDcNameChanged(event, dcName) {
      if (event.event && event.previousValue !== event.value) {
        if (!event.value) {
          // 입력한 데이터가 없으면 할인 ID = null
          this.groupInfo.dcId = null;
          this.groupInfo.dcName = null;
          return;
        }

        getDcCodeList("NOR", {
          searchDiv: null,
          searchContents: dcName || null,
        })
          .then((response) => {
            const norDcList = response.value.norDcList;
            if (norDcList && norDcList.length > 1) {
              // 2건 이상 검색 팝업
              this.onDcIdSearchPopupOpen();
            } else if (norDcList && norDcList.length === 1) {
              // 1건 검색 설정
              this.groupInfo.dcId = norDcList[0].dcId;
              this.groupInfo.dcName = norDcList[0].dcContents;
            } else {
              // 검색 건수 없음
              this.groupInfo.dcId = null;
              this.groupInfo.dcName = null;
            }
          })
          .catch((error) => {
            console.error("getDcCodeList.err.===>", error);
          });
      }
    },
    onNameChanged(event, name) {
      if (event.event && event.previousValue !== event.value) {
        if (event.value.trim() === "") {
          this.setNonMemberInfos(name);
          return;
        }
        // 회원정보에 없는 비회원이라도 무조건 회원 검색 팝업 호출. 모든 화면 공통 조건
        if (name === "generName") {
          this.onMemberPopupOpen(
            false,
            this.memberPopupType.GENER,
            event.value
          );
        } else if (name === "chairName") {
          this.onMemberPopupOpen(
            false,
            this.memberPopupType.CHAIR,
            event.value
          );
        }
      }
    },
    onDcIdSearchPopupOpen() {
      this.isDcIdSearchPopupOpen = true;
      this.$nextTick(() => {
        this.$refs.searchNormalDcPopup.showSearchNormalDcPopup(null, [], true);
      });
    },
    onMemberPopupOpen(
      isOpenedWithButton,
      popupType,
      memberNameNo,
      timeInfoIdx
    ) {
      this.isMemberSelectPopupOpen = true;

      let memberData = {};
      memberData.memberNameNo = memberNameNo;
      memberData.isOpenedWithButton = isOpenedWithButton;
      memberData.popupType = popupType;
      if (timeInfoIdx !== null && timeInfoIdx !== undefined) {
        memberData.timeInfoIdx = timeInfoIdx;
      }
      this.$nextTick(() => {
        this.$refs.memberSelectPopup.showPopup(memberData);
      });
    },
    memberSelectPopupConfirmed(data) {
      this.isMemberSelectPopupOpen = false;
      if (data.selectedRowData) {
        if (data.popupData.popupType === this.memberPopupType.GENER) {
          this.setMemberInfos(data.selectedRowData, "generName");
        } else if (data.popupData.popupType === this.memberPopupType.CHAIR) {
          this.setMemberInfos(data.selectedRowData, "chairName");
        } else if (data.popupData.popupType === this.memberPopupType.MEMBER) {
          this.setGrpMemberInfo(data.selectedRowData, this.grpMembersGridSelectedIdx);
        }
      }
    },
    setMemberInfos(memberInfo, name) {
      if (name === "generName") {
        this.groupInfo.generName = memberInfo.memberName;
        this.groupInfo.generMemberDiv = memberInfo.memberDiv;
        this.groupInfo.generMembershipId = memberInfo.membershipId;
        this.groupInfo.generContactTel = memberInfo.hpNo;
      } else if (name === "chairName") {
        this.groupInfo.chairName = memberInfo.memberName;
        this.groupInfo.chairMemberDiv = memberInfo.memberDiv;
        this.groupInfo.chairMembershipId = memberInfo.membershipId;
        this.groupInfo.chairContactTel = memberInfo.hpNo;
      }
    },
    memberSelectPopupClosed(data) {
      this.isMemberSelectPopupOpen = false;
      if (data.popupData.popupType === this.memberPopupType.GENER) {
        if (!data.popupData.isOpenedWithButton) {
          this.setNonMemberInfos("generName");
        }
      } else if (data.popupData.popupType === this.memberPopupType.CHAIR) {
        if (!data.popupData.isOpenedWithButton) {
          this.setNonMemberInfos("chairName");
        }
      } else if (data.popupData.popupType === this.memberPopupType.MEMBER) {
        if (!data.popupData.isOpenedWithButton) {
          // textbox를 통해 팝업을 띄웠는데, 선택하지 않고 닫은 경우)
          this.setGrpNonMemberInfo(this.grpMembersGridSelectedIdx);
        }
      }
    },
    setNonMemberInfos(name) {
      if (name === "generName") {
        this.groupInfo.generMembershipId = null;
        this.groupInfo.generMemberDiv = "NOM";
        this.groupInfo.generContactTel = null;
      } else if (name === "chairName") {
        this.groupInfo.chairMembershipId = null;
        this.groupInfo.chairMemberDiv = "NOM";
        this.groupInfo.chairContactTel = null;
      }
    },
    searchNormalDcPopupClosed(args) {
      this.isDcIdSearchPopupOpen = false;
      if (args !== undefined) {
        this.groupInfo.dcId = args.rowSelectedData.dcId;
        this.groupInfo.dcName = args.rowSelectedData.dcContents;
      }
    },
    async openGroupRegistrationPopup(grpNo) {
      this.$refs.groupManagementRegistrationPopup.show();

      if (!grpNo) {
        this.groupInfo.grpKind = "YEAR"; // commonCodeGetComCodeDefaultValue("GRP_KIND"); TODO : 단체 정보 등록 화면에서는 하드코딩으로 연단체 지정.
        this.groupInfo.resveChannel = commonCodeGetComCodeDefaultValue("RESVE_CHANNEL");
        return;
      }

      this.grpNo = grpNo;
      await this.getGroupInfoDetail(grpNo);

      let resveMtCount = 0;
      const monthData = this.monthList.map((monthData) => {
        const find = this.groupInfo.groupMonthTimeList.find(
          (x) => x.resveMt === monthData.month
        );
        if (find) {
          monthData.grpNo = this.groupInfo.grpNo;
          monthData.resveMt = find.resveMt;
          monthData.resveTime = find.resveTime;
          monthData.isCheck = true;
          resveMtCount++;
        } else {
          this.resveMtValueCheckAll = false;
        }
        return monthData;
      });

      if (resveMtCount === 12) {
        this.resveMtValueCheckAll = true;
      }

      this.monthList = {
        ...this.monthList,
        ...monthData,
      };

      this.refresh = false;
    },
    closeGroupRegistrationPopup(refresh) {
      if (refresh) {
        this.refresh = true;
      }
      this.$refs.groupManagementRegistrationPopup.hide();
    },
    validateResveTime() {
      let isValid = true;

      const monthArr = Object.values(this.monthList);
      const monthInputDatas = monthArr.filter(
        (month) => month.isCheck && !month.resveTime
      );

      if (monthInputDatas.length > 0) {
        this.errorToast("예약 시간을 입력해 주세요");
        isValid = false;
        return isValid;
      }

      for (let i = 0; i < 12; i++) {
        if (this.monthList[i].isCheck) {
          let resveTime = this.monthList[i].resveTime;

          if (resveTime == null) {
            this.errorToast("예약 시간을 입력해 주세요");
            isValid = false;
            return;
          } else if (!resveTime.includes(":")) {
            //새로 입력한 경우 문자열 ":"가 존재하지 않음
            resveTime = resveTime.substr(0, 2) + ":" + resveTime.substr(2, 4);
          }

          if (!resveTime.match(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/)) {
            this.errorToast(
              "예약 시간은 00:00에서 23:59까지 입력할 수 있습니다"
            );
            isValid = false;
            return;
          }
        }
      }
      return isValid;
    },
    onGroupCloseClicked() {
      this.closeGroupRegistrationPopup(false);
    },
    onGroupDialogCloseClicked() {
      this.$emit("close", { refresh: this.refresh });
    },
    onIsAutoAssignmentGrpNoChange(event) {
      if (event.target.checked) {
        this.groupInfo.grpNo = null;
      }
    },
    async onGroupDeleteClicked() {
      try {
        if (!(await this.confirm(this.$t("main.popupMessage.confirmDelete")))) {
          return;
        }

        await deleteGroupDetail(this.groupInfo.grpNo);
        this.closeGroupRegistrationPopup(true);
      } catch (e) {
        console.error(`${this.grpNo ? "update" : "create"}_group.err.===>`, e);
      }
    },
    async onGroupSaveClicked() {
      if (!this.validate()) {
        return;
      }
      if (!this.validateResveTime()) {
        return;
      }

      const task = this.grpNo ? putGroupDetail : postGroupDetail;
      try {
        this.groupInfo.groupMonthTimeList = [];
        for (let i = 0; i < 12; i++) {
          if (this.monthList[i].resveTime != null) {
            let resveTimeTemp = this.monthList[i].resveTime;

            this.groupInfo.groupMonthTimeList.push({
              resveMt: this.monthList[i].month,
              resveTime: resveTimeTemp.includes(":")
                ? resveTimeTemp
                : resveTimeTemp.substr(0, 2) +
                  ":" +
                  resveTimeTemp.substr(2, resveTimeTemp.length),
            });
          }
        }

        // 단체 회원
        const gridRefs = this.$refs.grpMembersGrid;

        if (!gridRefs) {
          return;
        }

        if (!gridRefs.validate()) {
          return;
        } else {
          const currentViewGrpMemberInfos = gridRefs.getBatchCurrentViewRecords();

          let duplicateGrpMember = false;
          currentViewGrpMemberInfos.forEach((data, idx) => {
            if (currentViewGrpMemberInfos.filter((info, infoIdx) =>
                (idx !== infoIdx && info.memberName === data.memberName && info.hpNo != undefined && info.hpNo === data.hpNo)
                || (idx !== infoIdx && info.memberName === data.memberName && info.membershipId === data.membershipId)
            ).length > 0) {
              duplicateGrpMember = true;
            }
          });

          if (duplicateGrpMember) {
            this.errorToast("단체 회원명 및 연락처가 중복됩니다");
            return;
          }
        }

        const batchChangesData = gridRefs.getBatchChanges();

        const saveGrpMemberList = [];

        if (batchChangesData.addedRecords.length > 0) {
          batchChangesData.addedRecords.forEach((data) => {
            saveGrpMemberList.push(data);
          });
        }

        if (batchChangesData.changedRecords.length > 0) {
          batchChangesData.changedRecords.forEach((data) => {
            if (data.memberDiv === "NOM") {
              data.membershipId = "";
              data.memberNo = "";
            }
            saveGrpMemberList.push(data);
          });
        }

        if (batchChangesData.deletedRecords.length > 0) {
          batchChangesData.deletedRecords.forEach((data) => {
            data.memberName = "";
            saveGrpMemberList.push(data);
          });
        }

        let isValidSmsRecptnFlag = false;
        saveGrpMemberList.forEach((data) => {
          delete data._rid;
          if(!data.hpNo) {
            delete data.hpNoIdx;

            if(data.smsRecptnFlag) {
              isValidSmsRecptnFlag = true;
            }
          }
        });

        if(isValidSmsRecptnFlag) {
          this.errorToast("연락처를 입력하지 않고 예약알림수신여부를 설정할 수 없습니다.");
          return;
        }

        const saveData = {
          ...this.groupInfo
        };

        saveData.grpMemberList = [];
        if(saveGrpMemberList.length > 0) {
          saveData.grpMemberList = saveGrpMemberList;
        }

        await task(saveData);

        this.infoToast("저장되었습니다");
        this.closeGroupRegistrationPopup(true);
      } catch (e) {
        console.error(`${this.grpNo ? "update" : "create"}_group.err.===>`, e);
      }
    },
    getGroupInfoDetail(grpNo) {
      return getGroupInfoDetail(grpNo)
        .then(({ value }) => {
          // 마스킹 된 데이터는 보여주지 않음
          if (!(value.generContactTel?.indexOf("*") < 0)) {
            delete value.generContactTel;
          }
          if (!(value.chairContactTel?.indexOf("*") < 0)) {
            delete value.chairContactTel;
          }

          this.groupInfo = {
            ...this.groupInfo,
            ...value,
          };
        })
        .catch((error) => {
          console.error("getGroupInfoDetail.err.===>", error);
        });
    },
    /**
     *
     * @return {boolean}
     */
    validate() {
      return this.validateFormRefs(this.validateRefList);
    },
    async onChangeHistoryButtonClicked() {
      const grpNo = this.groupInfo.grpNo;

      if (!grpNo) {
        this.errorToast("단체 정보가 없습니다");
        return;
      }

      const rawHistories = await GolfErpAPI.fetchGroupHistory(grpNo);

      this.isHistoryPopupOpen = true;
      this.$nextTick(() => {
        this.$refs.historyPopup.show({
          rawHistories,
          fields: {
            TgGrpMst: {
              __name__: "단체 정보",
              grpName: {
                name: "단체명",
                type: "string",
                methods: ["create", "update", "delete"],
              },
              grpKind: {
                name: "단체종류",
                type: "commonCode",
                groupCode: "GRP_KIND",
                methods: ["create", "update", "delete"],
              },
              teamCnt: {
                name: "팀수",
                type: "number",
                methods: ["create", "update", "delete"],
              },
              areaCode: {
                name: "지역코드",
                type: "commonCode",
                groupCode: "AREA_CODE",
                methods: ["create", "update", "delete"],
              },
              insertDate: {
                name: "등록일자",
                type: "string",
                methods: ["create", "update", "delete"],
              },
              resveCycle: {
                name: "예약주기",
                type: "commonCode",
                groupCode: "RESVE_CYCLE",
                methods: ["create", "update", "delete"],
              },
              dwCode: {
                name: "요일코드",
                type: "commonCode",
                groupCode: "DW_CODE",
                methods: ["create", "update", "delete"],
              },
              paymtCode: {
                name: "요금코드",
                type: "commonCode",
                groupCode: "PAYMT_CODE",
                methods: ["create", "update", "delete"],
              },
              dcId: {
                name: "할인ID",
                type: "number",
                methods: ["create", "update", "delete"],
              },
              generName: {
                name: "총무",
                type: "string",
                methods: ["create", "update", "delete"],
              },
              generMembershipId: {
                name: "총무회원권ID",
                type: "string",
                methods: ["create", "update", "delete"],
              },
              generContactTel: {
                name: "총무연락처",
                type: "string",
                methods: ["create", "update", "delete"],
              },
              chairName: {
                name: "회장",
                type: "string",
                methods: ["create", "update", "delete"],
              },
              chairMembershipId: {
                name: "회장회원권ID",
                type: "string",
                methods: ["create", "update", "delete"],
              },
              chairContactTel: {
                name: "회장연락처",
                type: "string",
                methods: ["create", "update", "delete"],
              },
              grpCharct: {
                name: "단체비고",
                type: "string",
                methods: ["create", "update", "delete"],
              },
              resveRemarks: {
                name: "계약조건",
                type: "string",
                methods: ["create", "update", "delete"],
              },
              frontRemarks: {
                name: "결제조건",
                type: "string",
                methods: ["create", "update", "delete"],
              },
              dpmnyIncomDate: {
                name: "예치금입금일자",
                type: "string",
                methods: ["create", "update", "delete"],
              },
              dpmnyIncomConfirm: {
                name: "예치금입금확인",
                type: "boolean",
                methods: ["create", "update", "delete"],
              },
              dpmnyIncomDiv: {
                name: "예치금입금구분",
                type: "commonCode",
                groupCode: "INCOM_DIV",
                methods: ["create", "update", "delete"],
              },
              dpmnyDpstrName: {
                name: "예치금예금주명",
                type: "string",
                methods: ["create", "update", "delete"],
              },
              dpmnyAmt: {
                name: "예치금액",
                type: "number",
                methods: ["create", "update", "delete"],
              },
              dpmnyReturnDate: {
                name: "예치금반환일자",
                type: "string",
                methods: ["create", "update", "delete"],
              },
              dpmnyReturnConfirm: {
                name: "예치금반환확인",
                type: "boolean",
                methods: ["create", "update", "delete"],
              },
              useFlag: {
                name: "사용여부",
                type: "boolean",
                methods: ["create", "update", "delete"],
              },
            },
            TgGrpMember: {
              __name__: "단체 회원 정보",
              memberName: {
                name: "이름",
                type: "string",
                methods: ["create", "update", "delete"],
              },
              hpNo: {
                name: "연락처",
                type: "string",
                methods: ["create", "update", "delete"],
              },
              smsRecptnFlag: {
                name: "예약알림수신여부",
                type: "boolean",
                methods: ["create", "update", "delete"],
              },
              sexCode: {
                name: "성별코드",
                type: "commonCode",
                groupCode: "SEX_CODE",
                methods: ["create", "update", "delete"],
              },
              memberNo: {
                name: "회원번호",
                type: "string",
                methods: ["create", "update", "delete"],
              },
              memberDiv: {
                name: "회원구분",
                type: "commonCode",
                groupCode: "MEMBER_DIV",
                methods: ["create", "update", "delete"],
              },
              remarks: {
                name: "비고",
                type: "string",
                methods: ["create", "update", "delete"],
              },
            }
          }
        });
      });
    },
    historyPopupClose: function () {
      this.isHistoryPopupOpen = false; // 닫히면 DOM에서 없애버림. 새로 띄울 때 초기화시키기
    },

    grpMembersGridHeaderCellInfo: function (args) {
      if (
          args.cell.column.field === "memberName" ||
          args.cell.column.field === "sexCode"
      ) {
        // 헤더에 필수영역 표시 클래스 추가
        args.node.classList.add(this.$t("className.grid.requiredInputHeader"));
      }

      if (args.cell.column.field === "memberNameIcon") {
        args.node.classList.add(this.$t("className.grid.searchIconArea"));
      }
    },
    grpMembersGridQueryCellInfo: function (args) {
      const {
        cell,
        column: {
          field
        }
      } = args;
      if (
          cell.classList.contains("e-gridchkbox") ||
          field === "memberName" ||
          field === "hpNo" ||
          field === "sexCode" ||
          field === "smsRecptnFlag"
      ) {
        cell.classList.add(this.$t("className.grid.modifyArea"));
      }

      // 수정영역 셀 처리
      // 검색 아이콘 영역
      if (field === "memberNameIcon") {
        cell.classList.add(this.$t("className.grid.searchIconArea"));
      }

      // 컬럼 속성 추가 - 클릭 시 클릭한 컬럼 여부 파악을 위함
      cell.setAttribute("column-name", args.column.field);
    },
    getGridIdxByRowElement: function (trElement) {
      return this.$refs.grpMembersGrid
          .getDataRows()
          .findIndex((dataRow) => dataRow === trElement);
    },
    grpMembersGridCellSave(args) {
      switch (args.columnName) {
        case "sexCode":
          this.setEjsDropdonwlistEditTemplateValue(args);
          break;
      }
    },
    /**
     * dropdown을 적용한 template에서 값 변경 후 focus out 시 셀에 값을 설정해주기 위함
     */
    setEjsDropdonwlistEditTemplateValue(args) {
      args.value = args.cell.firstElementChild.getElementsByClassName(
          "e-dropdownlist"
      )[0].ej2_instances[0].itemData.comCode;
    },
    // 회원 검색에 엔터만 사용할 경우 해당 함수 사용 x
    async grpMembersGridCellSaved(args) {
      const { columnName } = args;
      if (columnName === "memberName") {
        if (args.previousValue !== args.value) {

          if (args.value.trim() === "") {
            // 빈 칸인 경우에는 비회원 설정
            this.setGrpNonMemberInfo(this.grpMembersGridSelectedIdx);
            return;
          }

          const gridRefs = this.$refs.grpMembersGrid;
          const selectedRows = gridRefs.getSelectedRowIndexes();
          // 팝업 띄우기 전 save 처리
          this.$refs.grpMembersGrid.saveCell();
          let currentMemberInfo = this.$refs.grpMembersGrid.getBatchCurrentViewRecords()[selectedRows];
          this.onMemberPopupOpen(
              false,
              this.memberPopupType.MEMBER,
              currentMemberInfo.memberName,
              selectedRows,
              {
                // 팝업 띄운 후 "내장객" 포커싱
                editingCellField: "memberName",
                currentRowIndex: args.rowIndex,
              }
          );
        }
      }
    },
    /**
     * 단체 회원 그리드에 단체 회원 정보 설정
     * @param memberInfo
     * @param selectedGridIdx
     */
    async setGrpMemberInfo(memberInfo, selectedGridIdx) {
      this.$refs.grpMembersGrid.updateCell(
          selectedGridIdx,
          "memberName",
          memberInfo.memberName
      );
      this.$refs.grpMembersGrid.updateCell(
          selectedGridIdx,
          "sexCode",
          memberInfo.sexCode
      );
      this.$refs.grpMembersGrid.updateCell(
          selectedGridIdx,
          "smsRecptnFlag",
          false // 회원 정보 설정 시 기본값으로 세팅
      );
      this.$refs.grpMembersGrid.updateCell(
          selectedGridIdx,
          "membershipId",
          memberInfo.membershipId
      );
      this.$refs.grpMembersGrid.updateCell(
          selectedGridIdx,
          "memberNo",
          memberInfo.memberNo
      );
      this.$refs.grpMembersGrid.updateCell(
          selectedGridIdx,
          "memberDiv",
          memberInfo.memberDiv
      );

      // 암호화데이터 열람 권한이 있어야 설정 가능
      if (this.commonMixinHasCiperTextGet) {
        this.$refs.grpMembersGrid.updateCell(
            selectedGridIdx,
            "hpNo",
            memberInfo.hpNo
        );
      }
    },
    /**
     * 단체 회원 그리드에 비회원 정보 설정
     * @param memberInfo
     * @param selectedGridIdx
     */
    async setGrpNonMemberInfo(selectedGridIdx) {
      this.$refs.grpMembersGrid.updateCell(selectedGridIdx, "sexCode", "M");
      this.$refs.grpMembersGrid.updateCell(selectedGridIdx, "smsRecptnFlag", false);
      this.$refs.grpMembersGrid.updateCell(selectedGridIdx, "membershipId", null);
      this.$refs.grpMembersGrid.updateCell(selectedGridIdx, "memberNo", null);
      this.$refs.grpMembersGrid.updateCell(selectedGridIdx, "memberDiv", "NOM");

      // 암호화데이터 열람 권한이 있어야 설정 가능
      if (this.commonMixinHasCiperTextGet) {
        this.$refs.grpMembersGrid.updateCell(selectedGridIdx, "hpNo", "");
      }
    },
    grpMembersGridClicked(args) {
      if (!args.column) {
        return;
      }

      switch (args.column.field) {
        case "memberNameIcon": // 검색 아이콘 클릭
          // eslint-disable-next-line no-case-declarations
          let currentMemberInfo = this.$refs.grpMembersGrid.getBatchCurrentViewRecords()[
              args.rowIndex
              ];
          if (currentMemberInfo) {
            // 팝업 띄우기 전 save 처리
            this.$refs.grpMembersGrid.saveCell();

            this.onMemberPopupOpen(
                true,
                this.memberPopupType.MEMBER,
                currentMemberInfo.memberName,
                args.rowIndex,
                {
                  // 팝업 띄운 후 "내장객" 포커싱
                  editingCellField: "memberName",
                  currentRowIndex: args.rowIndex,
                }
            );
          }
          break;
      }
    },
    onGrpMembersGridClick(e) {
      if (e.srcElement) {
        const columnName = e.srcElement.getAttribute("column-name");
        if (columnName) {
          switch (columnName) {
            case "sexCode":
              this.gridGrpMembersEjsDropdownlistEditTemplateEventBus.$emit(
                  "click-open",
                  columnName
              );
              break;
          }
        }
      }
    },
    onGrpMemberGridRowSelected(args) {
      // rowIndex 기반으로 row selecting
      this.grpMembersGridSelectedIdx = args.rowIndex;
    },
    // 단체 회원 등록 추가
    onAddGrpMemberInfoClicked: function () {
      // const currentGridDataRows = this.$refs.grpMembersGrid.getDataRows();
      // const currentGridLength = currentGridDataRows.length;

      // this.addVisitsInfoRecord(currentGridLength + 1);
      this.addGrpMembersInfoRecord();
    },
    // 단체 회원 등록 삭제
    async onDeleteGrpMemberInfoClicked() {
      const deletedIdx = this.grpMembersGridSelectedIdx; // Single row select 그리드이기 때문에, 현재 선택된 row 만 지울 수 있음
      if (deletedIdx >= 0) {
          /*if (!(await this.confirm("단체 회원을 삭제하시겠습니까?"))) {
            return;
          }*/

        this.$refs.grpMembersGrid.deleteRecord();
      } else {
        this.errorToast("단체 회원을 선택해 주십시오");
      }
    },
    /**
     * 내장객 그리드에 1건의 내장객 추가
     */
    addGrpMembersInfoRecord() {
        let _rid;
        const { addedRecords } = this.$refs.grpMembersGrid.getBatchChanges();
        _rid = addedRecords.length;
        let addRecordObject = {
          _rid: _rid + 1,
          memberName: "",
          membershipId: "",
          memberNo: null,
          sexCode: commonCodeGetComCodeDefaultValue("SEX_CODE"),
          hpNo: "",
          smsRecptnFlag: false,
          memberDiv: "NOM",
          memberGrade: "NOR",
          remarks: "",
        };
        this.$refs.grpMembersGrid.addRecord(addRecordObject); // record add 순서가 영키지 않기 위해서 우선 add부터. 요금 설정은 batchAdd 이벤트에서 처리
        const currentviewRecords = this.$refs.grpMembersGrid.getBatchCurrentViewRecords();
        const idxToSelect = currentviewRecords.findIndex(
            (record) => record._rid === addRecordObject._rid
        );
      this.$refs.grpMembersGrid.editCell(idxToSelect, "memberName");
    },
    onKeyPressed(args) {
      if (
          !(
              args.target.id === "memberName" &&
              (args.key === "Escape" || args.key === "Enter")
          )
      ) {
        return;
      }
      const gridRefs = this.$refs.grpMembersGrid;
      const selectedRows = gridRefs.getSelectedRowIndexes();
      if (selectedRows.length < 1) {
        return;
      }
      const selectedRow = selectedRows[0];
      if (args.key === "Escape") {
        gridRefs.updateCell(selectedRow, "memberName", "");
        gridRefs.clearSelection();
      } else if (args.key === "Enter") {
        // 팝업 띄우기 전 save 처리
        this.$refs.grpMembersGrid.saveCell();
        let currentMemberInfo = this.$refs.grpMembersGrid.getBatchCurrentViewRecords()[selectedRows];
        this.onMemberPopupOpen(
            false,
            this.memberPopupType.MEMBER,
            currentMemberInfo.memberName,
            selectedRows,
            {
              // 팝업 띄운 후 "내장객" 포커싱
              editingCellField: "memberName",
              currentRowIndex: args.rowIndex,
            }
        );
      }
    },
  },
};
</script>
<style scoped>
body .window.groupInformation .windowContent .content-wrapper .body-article .custom-section-body {
  flex: 1;
  overflow: auto;
  box-sizing: border-box;
  border: 1px solid #ccc;
  background-color: #fff;
}
.inputDate .inputDate-input {width: calc(100% - 30px) !important;}
</style>