<template>
  <div>
    <ejs-dialog
      ref="searchNormalDcPopup"
      :header="`할인코드 검색`"
      :allowDragging="true"
      :showCloseIcon="true"
      width="937"
      height="600"
      :animationSettings="{ effect: 'None' }"
      :isModal="true"
      :close="onSearchNormalDcPopupClosed"
    >
      <div class="window discountCodeSearch">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-lookup">
              <div class="lookup-left">
                <ul class="lookup-condition">
                  <li class="field discountKind">
                    <div class="title">할인종류</div>
                    <ul class="content">
                      <li class="item">
                        <ejs-dropdownlist
                          v-model="searchDiv"
                          :dataSource="searchOptions.dcKind"
                          :allowFiltering="false"
                          :fields="searchOptions.dcKindFields"
                          cssClass="lookup-condition-dropdown"
                          :enabled="searchDivEnabled"
                          :tabindex="-1"
                        ></ejs-dropdownlist>
                      </li>
                      <li class="item">
                        <input-text
                          ref="searchContentsTextBox"
                          id="searchContentsTextBox"
                          v-model="searchContents"
                          :tabindex="-1"
                          @keydown.enter="onSearchContentsKeyDownEnter"
                        />
                      </li>
                    </ul>
                  </li>
                  <li class="field">
                    <ul class="content">
                      <li class="item">
                        <ul class="check">
                          <li>
                            <label>
                              <input
                                type="checkbox"
                                id="useFlag"
                                v-model="useFlag"
                                value="true"
                                :tabindex="-1"
                              />
                              <i></i>
                              <div class="label">사용</div>
                            </label>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
                <div class="lookup-lookup">
                  <erp-button
                      button-div="GET"
                      :is-shortcut-button="true"
                      v-on:click.native="search"
                  >
                    조회
                  </erp-button>
                </div>
              </div>
            </div>
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-body">
                    <div class="body-grid">
                      <ejs-grid-wrapper
                        ref="norDcListGrid"
                        id="norDcList"
                        :allowResizing="true"
                        :dataSource="norDcList"
                        :selectionSettings="selectionSettings"
                        :allowExcelExport="true"
                        :columns="norDcListGridColumn"
                        :provides="grid"
                        :isSelectedRowRetain="false"
                        :isInPopup="true"
                        @queryCellInfo="norDcListGridQueryCellInfo"
                        @rowSelected="norDcListGridRowSelected"
                        @onGridDialogDoubleClickedOrEnterKeyed="
                          onNorDcListGridDoubleClick
                        "
                        @dataBound="onNorDcListGridDataBound"
                      />
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="confirm keyColor">
              <erp-button
                  button-div="SAVE"
                  :is-icon-custom="true"
                  @click.native="confirmSearchNormalDcPopup"
              >
                확인
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="closeSearchNormalDcPopup">
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import {
  Aggregate,
  Filter,
  Resize,
  Group,
  ExcelExport,
  ForeignKey,
} from "@syncfusion/ej2-vue-grids";
import {
  commonCodesGetCommonCode,
  commonCodesGetComName,
} from "@/utils/commonCodes";
import { numberWithCommas } from "@/utils/number";
import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import numberTemplate from "@/views/common/template/GridNumberTemplate";

import { getDcCodeList } from "@/api/frontManagementCheckin";
import InputText from "@/components/common/text/InputText";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";

import ErpButton from "@/components/button/ErpButton.vue";

export default {
  name: "searchNormalDcPopup",
  mixins: [confirmDialogMixin],
  components: {
    InputText,
    EjsGridWrapper,
    ErpButton,
  },
  created() {
    this.searchOptions.dcKind = commonCodesGetCommonCode("DC_KIND");
    this.searchOptions.dcKind.unshift({
      comCode: "%",
      comName: "전체",
      codeAbrv: "",
      defaultFlag: false,
    });
  },
  data() {
    return {
      grid: [Aggregate, Filter, Resize, Group, ExcelExport, ForeignKey],
      changeIdx: null,
      numberTemplate() {
        return {
          template: numberTemplate,
        };
      },
      searchDiv: "FEE", // 리뷰를 위해 임시로 할인종류 입장료가 셋팅되도록 수정. 원래는 NULL 설정되어있었음.
      searchContents: "",
      useFlag: true,
      searchOptions: {
        dcKind: [],
        dcKindFields: { text: "comName", value: "comCode" },
      },
      norDcList: [],
      selectionSettings: {
        type: "Single",
        mode: "Both",
        enableToggle: false,
        persistSelection: false,
      },
      rowSelectedData: null,
      greenFeeDcList: [],
      imageTemplate() {
        return {
          template: Vue.component("imageTemplate", {
            template: `<div class="image"><img :src="data.image"/></div>`,
            data() {
              return {
                data: {},
              };
            },
          }),
        };
      },
      norDcListGridColumn: [
        {
          field: "dcKind",
          headerText: "할인종류",
          width: 90,
          textAlign: "Left",
          isCommonCodeField: true,
          groupCode: "DC_KIND",
          type: "string",
        },
        {
          field: "dcContents",
          headerText: "할인내용",
          width: 240,
          textAlign: "Left",
          type: "string",
        },
        {
          field: "dcMethod",
          headerText: "할인방법",
          width: 90,
          textAlign: "Left",
          isCommonCodeField: true,
          groupCode: "DC_METHOD",
          type: "string",
        },
        {
          field: "dcAmt",
          headerText: "할인금액(율)",
          width: 120,
          textAlign: "Right",
          type: "string",
        },
        {
          field: "remarks",
          headerText: "비고",
          width: 240,
          textAlign: "Left",
          type: "string",
        },
        {
          field: "image",
          headerText: "이미지",
          width: 60,
          textAlign: "center",
          template: this.imageTemplate,
          type: "string",
        },
      ],
      searchDivEnabled: false,
    };
  },
  methods: {
    commonCodesGetComName,
    valueAccessor(field, data, column) {
      let code = data[field];
      let returnCode = null;
      if (code != null) {
        returnCode = commonCodesGetComName(column.comCode, code);
      }
      return returnCode;
    },
    showPopup() {
      this.$refs.searchNormalDcPopup.show();
    },
    norDcListGridQueryCellInfo(args) {
      if (args.column.field === "dcAmt") {
        // 할인금액
        if (args.data.dcMethod === "AMT") {
          args.cell.innerHTML = numberWithCommas(args.cell.innerHTML) + "원";
        } else if (args.data.dcMethod === "RATE") {
          args.cell.innerHTML = numberWithCommas(args.cell.innerHTML) + "%";
        }
      }
    },
    closeSearchNormalDcPopup() {
      this.$refs.searchNormalDcPopup.hide();
    },
    confirmSearchNormalDcPopup() {
      if (this.rowSelectedData !== null) {
        let dplctDcCnt = 0;
        this.greenFeeDcList.forEach((data) => {
          if (data.dcApplyDiv === "COUPON" && !data.dplctDcFlag) {
            dplctDcCnt++;
          }
        });

        if (dplctDcCnt > 0) {
          this.errorToast("중복하여 할인할 수 없습니다");
          return;
        }

        this.$emit("popupClosed", {
          changeIdx: this.changeIdx,
          rowSelectedData: this.rowSelectedData,
        });
      } else {
        this.$emit("popupClosed");
      }
    },
    onSearchNormalDcPopupClosed() {
      this.$emit("popupClosed");
    },
    async getNorDcList() {
      // 할인코드 검색 팝업의 할인코드 조회 API 호출
      const response = await getDcCodeList("NOR", {
        searchDiv: this.searchDiv === "%" ? null : this.searchDiv, // 조회구분
        searchContents: this.searchContents, // 조회내용
        useFlag: this.useFlag, // 사용가능여부
        benMembershipId: null, // 특전회원권ID
        membershipId: null, // 회원권ID
        memberNo: null, // 회원번호
        memberDiv: null, // 회원구분
        memberGrade: null, // 회원등급
      });

      this.norDcList = response.value.norDcList.map((data) => {
        data.dcUseNo = data.dcId;
        return data;
      });
    },
    async search() {
      await this.getNorDcList();
    },
    async showSearchNormalDcPopup(changeIdx, dcList, openFlag = false) {
      this.changeIdx = changeIdx;
      this.greenFeeDcList = dcList;

      if (openFlag) {
        this.searchDivEnabled = true;
        this.searchDiv = "%";
      }

      await this.getNorDcList();
    },
    norDcListGridRowSelected(args) {
      this.rowSelectedData = {
        applyAmt: 0, // DiscountPopup.vue에서 적용금액 바인딩을 위해 설정함
        _rid: 0, // v-for 키로 사용하기 위함
        ...args.data,
        dcContents: args.data.dcContents + (
          args.data.dcMethod === "RATE" ? "(" + numberWithCommas(args.data.dcAmt) + "%)" :
            args.data.dcMethod === "AMT" ? "(" + numberWithCommas(args.data.dcAmt) + ")" : ""
        )
      };
    },
    onNorDcListGridDoubleClick() {
      this.confirmSearchNormalDcPopup();
    },
    async onSearchContentsKeyDownEnter() {
      await this.search();
    },
    onNorDcListGridDataBound() {
      if (this.norDcList.length > 0) {
        this.$refs.norDcListGrid.selectRow(0);
      } else {
        document.getElementById("searchContentsTextBox").select();
      }
    },
  },
};
</script>
