<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">단체/총무명</div>
            <ul class="content">
              <li class="item">
                <input-text
                  class="e-input"
                  v-model="searchConditions.searchValue"
                  @focus="onSearchValueClear"
                  @keydown.native="onSearchValueKeyDown"
                />
              </li>
<!--              <li class="item" style="width: 110px;">-->
<!--                <ul class="check">-->
<!--                  <li>-->
<!--                    <label>-->
<!--                      <input-->
<!--                        type="checkbox"-->
<!--                        id="isDepositMoney"-->
<!--                        v-model="searchConditions.isDepositMoney"-->
<!--                        value="true"-->
<!--                      />-->
<!--                      <i></i>-->
<!--                      <div class="label">위약 예치금 납부</div>-->
<!--                    </label>-->
<!--                  </li>-->
<!--                </ul>-->
<!--              </li>-->
            </ul>
          </li>
          <li class="field">
            <div class="title">단체종류</div>
            <ul class="content">
              <li class="item">
                <ejs-dropdownlist
                  v-model="searchConditions.grpKind"
                  :dataSource="searchOptions.grpKindOptions"
                  :fields="{ text: 'comName', value: 'comCode' }"
                  cssClass="lookup-condition-dropdown"
                  />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">단체구분</div>
            <ul class="content">
              <li class="item">
                <ejs-dropdownlist
                    v-model="searchConditions.grpDiv"
                    :dataSource="searchOptions.grpDivOptions"
                    :fields="{ text: 'comName', value: 'comCode' }"
                    cssClass="lookup-condition-dropdown"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">시작월</div>
            <ul class="content">
              <li class="item" style="width:100px;">
                <ejs-dropdownlist
                    v-model="searchConditions.resveMm"
                    :dataSource="searchOptions.resveMmOption"
                    :fields="{ text: 'comName', value: 'comCode' }"
                    cssClass="lookup-condition-dropdown"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <ul class="content">
              <li class="item">
                <ul class="check">
                  <li>
                    <label>
                      <input
                          type="checkbox"
                          v-model="searchConditions.useFlag"
                      />
                      <i/>
                      <div class="label">사용여부</div>
                    </label>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              button-div="GET"
              :is-shortcut-button="true"
              :ignore="isPopupOpened"
              v-on:click.native="onViewButtonClicked"
          >
            조회
          </erp-button>
        </div>
      </div>
      <div class="lookup-right">
        <div class="lookup-detail">
          <erp-button
              button-div="GET"
              :is-icon-custom="true"
              @click.native="onSearchDetailPopupOpen"
          >
            상세검색
          </erp-button>
        </div>
      </div>
    </div>
    <div class="content-body">
      <article class="body-article">
        <!-- 아코디언 : accordion / 닫힘 : close -->
        <section class="article-section section-01">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">단체 목록</div>
              <div class="header-caption">[{{ count }}건]</div>
            </div>
            <div class="header-right">
              <ul class="header-button">
                 <li>
                  <erp-button
                      button-div="SAVE"
                      :ignore="isPopupOpened"
                      :is-icon-custom="true"
                      :is-custom-shortcut-button="true"
                      shortcut-key="groupManagementRegistration.shortcuts.add"
                      :shortcut="{key: 'F7'}"
                      @click.native="groupSmsSend"
                  >
                    단체 SMS전송
                  </erp-button>
                </li>
                <li class="add">
                  <erp-button
                      button-div="SAVE"
                      :ignore="isPopupOpened"
                      :is-icon-custom="true"
                      :is-custom-shortcut-button="true"
                      shortcut-key="groupManagementRegistration.shortcuts.add"
                      :shortcut="{key: 'F3'}"
                      @click.native="onGroupCreateClicked"
                  >
                    추가
                  </erp-button>
                </li>
                <li class="delete">
                  <erp-button
                      button-div="DELETE"
                      :ignore="isPopupOpened"
                      :is-shortcut-button="true"
                      @click.native="onGroupDeleteClicked"
                  >
                    삭제
                  </erp-button>
                </li>
                <li class="print">
                  <erp-button
                      button-div="FILE"
                      @click.native="onClickExcel"
                  >
                    Excel
                  </erp-button>
                </li>
              </ul>
            </div>
          </div>
          <div class="section-body">
            <ejs-grid-wrapper
              ref="groupManagementRegistration"
              id="groupManagementRegistration"
              :width="'100%'"
              :rowHeight="24"
              :gridLines="'Both'"
              :height="'100%'"
              :provides="provide"
              :columns="columns"
              :dataSource="groupInfoList"
              :enableResize="true"
              :allowResizing="true"
              :allowSelection="true"
              :allowPaging="true"
              :allowExcelExport="true"
              :selectionSettings="selectionSettings"
              :pageSettings="pageSettings"
              :isSelectedRowRetain="true"
              @headerCellInfo="headerCellInfo"
              @queryCellInfo="queryCellInfo"
              @recordClick="recordClick"
              @actionComplete="gridActionComplete"
            />
          </div>
        </section>
      </article>
    </div>
    <ejs-dialog
      ref="searchDetailPopup"
      :header="`상세검색`"
      :allowDragging="true"
      :showCloseIcon="true"
      width="387"
      :animationSettings="{ effect: 'None' }"
      :isModal="false"
      :visible="false"
      enableResize="true"
      v-show="isSearchDetailPopupOpen"
    >
      <div class="window lookupDetail-groupManagementRegistration">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-lookupDetail">
              <ul class="lookupDetail-condition">
                <li class="field">
                  <div class="title">팀수</div>
                  <ul class="content">
                    <li class="item">
<!--                      <ejs-dropdownlist-->
<!--                        v-model="groupInfoOption.grpKind"-->
<!--                        :dataSource="searchOptions.grpKindOptions"-->
<!--                        :fields="{ text: 'comName', value: 'comCode' }"-->
<!--                        :allowFiltering="false"-->
<!--                        cssClass="lookupDetail-condition-dropdown"-->
<!--                      />-->
                      <input-text
                          id="teamCnt"
                          ref="teamCnt"
                          v-model="groupInfoOption.teamCnt"
                      />
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">예약주기</div>
                  <ul class="content">
                    <li class="item">
                      <ejs-dropdownlist
                          v-model="groupInfoOption.resveCycle"
                          :dataSource="searchOptions.resveCycleOption"
                          :fields="{ text: 'comName', value: 'comCode' }"
                          :allowFiltering="false"
                          cssClass="lookupDetail-condition-dropdown"
                      />
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">요일</div>
                  <ul class="content">
                    <li class="item">
                      <ejs-dropdownlist
                          v-model="groupInfoOption.dwCode"
                          :dataSource="searchOptions.dwCodeOption"
                          :fields="{ text: 'comName', value: 'comCode' }"
                          :allowFiltering="false"
                          cssClass="lookupDetail-condition-dropdown"
                      />
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">희망시간</div>
                  <ul class="content">
                    <li class="item">
                      <ejs-dropdownlist
                          v-model="groupInfoOption.grpHopeTime"
                          :dataSource="searchOptions.grpHopeTimeOption"
                          :fields="{ text: 'comName', value: 'comCode' }"
                          :allowFiltering="false"
                          cssClass="lookupDetail-condition-dropdown"
                      />
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">지역</div>
                  <ul class="content">
                    <li class="item">
                      <input-text
                          id="areaCode"
                          ref="areaCode"
                          v-model="groupInfoOption.areaCode"
                      />
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">비고</div>
                  <ul class="content">
                    <li class="item">
                      <input-text
                          id="grpCharct"
                          ref="grpCharct"
                          v-model="groupInfoOption.grpCharct"
                      />
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title"></div>
                  <ul class="content">
                    <li class="item check">
                      <ul class="check">
                        <li>
                          <label>
                            <input
                                type="checkbox"
                                id="isDepositMoney"
                                v-model="groupInfoOption.isDepositMoney"
                                value="true"
                            />
                            <i></i>
                            <div class="label">위약 예치금 납부</div>
                          </label>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>

<!--                <li class="field">-->
<!--                  <div class="title required">사용여부</div>-->
<!--                  <ul class="content">-->
<!--                    <li class="item check">-->
<!--                      <ul class="check">-->
<!--                        <li>-->
<!--                          <label>-->
<!--                            <input-->
<!--                              type="radio"-->
<!--                              id="useFlagAll"-->
<!--                              v-model="searchConditions.useFlag"-->
<!--                            />-->
<!--                            <i></i>-->
<!--                            <div class="label">ALL</div>-->
<!--                          </label>-->
<!--                        </li>-->
<!--                        <li>-->
<!--                          <label>-->
<!--                            <input-->
<!--                              type="radio"-->
<!--                              id="useFlagUse"-->
<!--                              value="true"-->
<!--                              v-model="searchConditions.useFlag"-->
<!--                            />-->
<!--                            <i></i>-->
<!--                            <div class="label">사용</div>-->
<!--                          </label>-->
<!--                        </li>-->
<!--                        <li>-->
<!--                          <label>-->
<!--                            <input-->
<!--                              type="radio"-->
<!--                              id="useFlagNotUse"-->
<!--                              value="false"-->
<!--                              v-model="searchConditions.useFlag"-->
<!--                            />-->
<!--                            <i></i>-->
<!--                            <div class="label">미사용</div>-->
<!--                          </label>-->
<!--                        </li>-->
<!--                      </ul>-->
<!--                    </li>-->
<!--                  </ul>-->
<!--                </li>-->
<!--                <li class="field">-->
<!--                  <div class="title">예약월</div>-->
<!--                  <ul class="content">-->
<!--                    <li class="item form-group">-->
<!--                      <ul class="check">-->
<!--                        <li>-->
<!--                          <label>-->
<!--                            <input-->
<!--                              type="checkbox"-->
<!--                              value="true"-->
<!--                              @change="resveMtAllCheck"-->
<!--                              :checked="-->
<!--                                searchConditions.resveMtList.length === 12-->
<!--                              "-->
<!--                              v-model="searchConditions.resveMtValueCheckAll"-->
<!--                            />-->
<!--                            <i></i>-->
<!--                            <div class="label">전체</div>-->
<!--                          </label>-->
<!--                        </li>-->
<!--                        <li-->
<!--                          v-for="monthNum in [-->
<!--                            1,-->
<!--                            2,-->
<!--                            3,-->
<!--                            4,-->
<!--                            5,-->
<!--                            6,-->
<!--                            7,-->
<!--                            8,-->
<!--                            9,-->
<!--                            10,-->
<!--                            11,-->
<!--                            12,-->
<!--                          ]"-->
<!--                          :key="monthNum"-->
<!--                        >-->
<!--                          <label>-->
<!--                            <input-->
<!--                              type="checkbox"-->
<!--                              @change="resveMtCheck"-->
<!--                              v-model="searchConditions.resveMtList"-->
<!--                              :value="monthNum"-->
<!--                            />-->
<!--                            <i></i>-->
<!--                            <div class="label">{{ monthNum }}월</div>-->
<!--                          </label>-->
<!--                        </li>-->
<!--                      </ul>-->
<!--                    </li>-->
<!--                  </ul>-->
<!--                </li>-->
              </ul>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="lookup keyColor">
              <erp-button
                  button-div="GET"
                  :is-shortcut-button="true"
                  :ignore="isPopupOpened"
                  v-on:click.native="onViewButtonClicked"
              >
                조회
              </erp-button>
            </li>
            <li class="reset">
              <erp-button
                  button-div="GET"
                  :is-icon-custom="true"
                  @click.native="onDialogSearchConditionInitClicked">
                초기화
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="onSearchDetailPopupClose">
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
    <group-management-registration-popup
      ref="groupManagementRegistrationPopup"
      @close="onGroupRegistrationPopupClose"
      v-if="isVisibleGroupRegistrationPopup"
    />
    <batch-sms-send-popup
      v-if="isBatchSmsSendPopupOpen"
      ref="batchSmsSendPopup"
      @popupClosed="batchSmsSendPopupClosed"
    />
  </div>
</template>

<style scoped>
body .appContent .lookup-condition .field .content .item {width: 180px;}
body .appContent .body-article .section-body {overflow: hidden;border: none;}
</style>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";
import { gridUtilGetTelColumnAccess } from "@/utils/gridUtil";
import { getGroupInfoList, deleteGroupDetail } from "@/api/group";
import {
  commonCodesGetCommonCode,
  commonCodesGetComName,
  commonCodesGetCommonCodeAttrbNameByCodeAndIdx,
} from "@/utils/commonCodes";
import { numberWithCommas } from "@/utils/number";
import groupManagementRegistrationPopup from "./popup/GroupManagementRegistrationPopup";
import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import {
  Page,
  Resize,
  Selection,
  ExcelExport,
  ForeignKey,
} from "@syncfusion/ej2-vue-grids";
import routeViewMixin from "@/views/layout/mixin/routeViewMixin";
import InputText from "@/components/common/text/InputText";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import batchSmsSendPopup from "../common/BatchSmsSendPopup";

import { SHORTCUT_KEYS } from "@/utils/KeyboardUtil";
import ErpButton from "@/components/button/ErpButton.vue";

export default {
  name: "groupManagementRegistration",
  mixins: [routeViewMixin, commonMixin, confirmDialogMixin],
  components: {
    InputText,
    groupManagementRegistrationPopup,
    EjsGridWrapper,
    batchSmsSendPopup,
    ErpButton
  },
  watch: {
    $route() {
      this.$refs.searchDetailPopup.hide();
      this.isSearchDetailPopupOpen = false;
    },
  },
  computed: {
    isPopupOpened() {
      return (
        this.isVisibleGroupRegistrationPopup || this.isBatchSmsSendPopupOpen
      );
    },
    sendSmsButtonProps() {
      return {
        shortcutKey: "groupManagementRegistration.shortcuts.sendSms",
        shortcut: {
          ctrlKey: false,
          altKey: false,
          shiftKey: false,
          key: SHORTCUT_KEYS.F7,
        },
      };
    },
  },
  data() {
    return {
      count: 0,
      groupInfoList: [],
      isSearchDetailPopupOpen: false,
      isVisibleGroupRegistrationPopup: false,
      searchConditions: {
        grpKind: "",  //단체 종류
        grpDiv: "", //단체 구분
        resveMm: "",  // 시작월
        // resveMtValue: [],
        searchValue: null,  //단체/총무명
        useFlag: true,  // 사용여부
        // resveMtValueCheckAll: false,
        // resveMtList: [],
      //
        teamCnt:'',
      },
      searchOptions: {
        grpKindOptions: commonCodesGetCommonCode("GRP_KIND", true),
        grpDivOptions: commonCodesGetCommonCode("GRP_DIV", true),
        resveMmOption: commonCodesGetCommonCode("RESVE_MM", true),
        resveCycleOption: commonCodesGetCommonCode("RESVE_CYCLE", true),
        dwCodeOption: commonCodesGetCommonCode("DW_CODE", true),
        grpHopeTimeOption: commonCodesGetCommonCode("GRP_HOPE_TIME", true),
      },
      groupInfoOption: {
        teamCnt:null,  //팀수
        areaCode: null, //지역
        // grpKind: "",
        resveCycle:"",  //예약주기
        dwCode:"", //요일
        grpHopeTime:"", //희망시간
        grpCharct: null, //비고
        isDepositMoney: false,  //위약예치금
      },
      provide: [Page, Resize, Selection, ExcelExport, ForeignKey],
      selectionSettings: {
        type: "Multiple",
        mode: "Both",
        enableToggle: false,
      },
      pageSettings: { pageSize: 50 },
      columns: [
        {
          field: "grpKind",
          headerText: "단체종류",
          type: "string",
          width: 100,
          textAlign: "left",
        },
        {
          field: "grpDiv",
          headerText: "단체구분",
          type: "string",
          width: 120,
          textAlign: "center",
          groupCode: "GRP_DIV",
          isCommonCodeField: true,
        },
        {
          field: "grpNo",
          headerText: "단체번호",
          allowEditing: false,
          type: "string",
          minWidth: 20,
          width: 100,
          textAlign: "center",
        },
        {
          field: "grpName",
          headerText: "단체명",
          allowEditing: false,
          type: "string",
          minWidth: 20,
          width: 120,
          textAlign: "left",
        },
        {
          field: "insertDate",
          headerText: "등록일자",
          allowEditing: false,
          type: "string",
          isDateType: true,
          minWidth: 16,
          width: 100,
          textAlign: "left",
        },
        {
          field: "useFlag",
          headerText: "사용",
          allowEditing: false,
          minWidth: 16,
          width: 80,
          textAlign: "center",
          editType: "booleanedit",
          type: "boolean",
          displayAsCheckBox: true,
        },
        {
          field: "resveMm",
          headerText: "시작월",
          allowEditing: false,
          type: "string",
          minWidth: 16,
          width: 100,
          textAlign: "right",
          valueAccessor: (field, data)=>{
            let resveMm = data[field];
            if (resveMm != null) {
              resveMm = resveMm + "월";
            }
            return resveMm;
          },
        },
        {
          field: "resveCycle",
          headerText: "예약주기",
          allowEditing: false,
          type: "string",
          minWidth: 16,
          width: 100,
          textAlign: "right",
          valueAccessor: this.resveCycleValueAccess,
        },
        {
          field: "dwCode",
          headerText: "예약요일",
          allowEditing: false,
          type: "string",
          minWidth: 16,
          width: 100,
          textAlign: "center",
          groupCode: "DW_CODE",
          isCommonCodeField: true,
        },
        {
          field: "grpHopeTime",
          headerText: "희망시간",
          allowEditing: false,
          type: "string",
          minWidth: 16,
          width: 185,
          textAlign: "left",
          groupCode: "GRP_HOPE_TIME",
          isCommonCodeField: true,
        },
        {
          field: "teamCnt",
          headerText: "신청팀수",
          allowEditing: false,
          type: "string",
          minWidth: 16,
          width: 80,
          textAlign: "right",
          valueAccessor: this.teamCntValueAccess,
        },
        {
          field: "areaCode",
          headerText: "지역",
          allowEditing: false,
          type: "string",
          minWidth: 16,
          width: 90,
          textAlign: "left",
        },
        {
          field: "resveChannel",
          headerText: "예약채널",
          allowEditing: false,
          type: "string",
          minWidth: 16,
          width: 100,
          textAlign: "center",
          groupCode: "RESVE_CHANNEL",
          isCommonCodeField: true,
        },
        {
          field: "generName",
          headerText: "총무명",
          allowEditing: false,
          type: "string",
          minWidth: 16,
          width: 90,
          textAlign: "left",
        },
        {
          field: "generContactTel",
          headerText: "총무연락처",
          allowEditing: false,
          type: "string",
          minWidth: 16,
          width: 120,
          textAlign: "left",
          valueAccessor: this.contactTelValueAccess,
        },
        {
          field: "chairName",
          headerText: "회장명",
          allowEditing: false,
          type: "string",
          minWidth: 16,
          width: 90,
          textAlign: "left",
        },
        {
          field: "chairContactTel",
          headerText: "회장연락처",
          allowEditing: false,
          type: "string",
          minWidth: 16,
          width: 120,
          textAlign: "left",
          valueAccessor: this.contactTelValueAccess,
        },
        {
          field: "frontRemarks",
          headerText: "결제조건",
          allowEditing: false,
          type: "string",
          minWidth: 16,
          width: 180,
          textAlign: "left",
        },
        {
          field: "grpCharct",
          headerText: "비고",
          allowEditing: false,
          type: "string",
          minWidth: 16,
          width: 180,
          textAlign: "left",
        },
        {
          field: "resveMtTime",
          headerText: "예약월/시간",
          allowEditing: false,
          type: "string",
          minWidth: 16,
          width: 185,
          textAlign: "left",
        },
      ],
      groupInfoListLength: 0,
      isBatchSmsSendPopupOpen: false,
    };
  },
  created() {
    this.searchOptions.grpKindOptions.unshift({ comCode: "", comName: "전체" });
    this.searchOptions.grpDivOptions.unshift({ comCode: "", comName: "전체" });
    this.searchOptions.resveMmOption.unshift({ comCode: "", comName: "전체" });
    this.searchOptions.resveCycleOption.unshift({ comCode: "", comName: "전체" });
    this.searchOptions.dwCodeOption.unshift({ comCode: "", comName: "전체" });
    this.searchOptions.grpHopeTimeOption.unshift({ comCode: "", comName: "전체" });
    this.getGroupInfoList();
  },
  methods: {
    numberWithCommas,
    contactTelValueAccess: gridUtilGetTelColumnAccess,
    teamCntValueAccess(field, data) {
      let teamCnt = data[field];
      if (teamCnt != null || teamCnt !== undefined) {
        teamCnt = teamCnt + "팀";
      } else {
        teamCnt = "0팀";
      }
      return teamCnt;
    },
    resveCycleValueAccess(field, data) {
      let resveCycle = data[field];
      if (resveCycle != null) {
        resveCycle = resveCycle + "주";
      }
      return resveCycle;
    },
    headerCellInfo(args) {
      if (
        args.cell.column &&
        (args.cell.column.field === "grpNo" ||
          args.cell.column.field === "grpName")
      ) {
        args.node.classList.add(this.$t("className.grid.clickArea"));
      }
    },
    queryCellInfo(args) {
      if (
        args.column &&
        (args.column.field === "grpNo" || args.column.field === "grpName")
      ) {
        args.cell.classList.add(this.$t("className.grid.clickArea"));
      }

      if (args.column && !args.data.useFlag) {
        args.cell.style.textDecoration = "line-through";
        args.cell.style.textDecorationColor = "red";
      }
    },
    recordClick(args) {
      const field = args.column.field;

      if (field === "grpNo" || field === "grpName") {
        this.openGroupRegistrationPopup(args.rowData.grpNo);
      }
    },
    openGroupRegistrationPopup(grpNo) {
      this.isVisibleGroupRegistrationPopup = true;
      this.$nextTick(() => {
        if (!grpNo) {
          this.$refs.groupManagementRegistrationPopup.openGroupRegistrationPopup();
        } else {
          this.$refs.groupManagementRegistrationPopup.openGroupRegistrationPopup(
            grpNo
          );
        }
      });
    },
    closeGroupRegistrationPopup() {
      this.isVisibleGroupRegistrationPopup = false;
    },
    // resveMtAllCheck(args) {
    //   if (args.target.checked) {
    //     this.searchConditions.resveMtList = [
    //       1,
    //       2,
    //       3,
    //       4,
    //       5,
    //       6,
    //       7,
    //       8,
    //       9,
    //       10,
    //       11,
    //       12,
    //     ];
    //   } else {
    //     this.searchConditions.resveMtList = [];
    //   }
    // },
    // resveMtCheck() {
    //   if (this.searchConditions.resveMtList.length === 12) {
    //     this.searchConditions.resveMtValueCheckAll = true;
    //   } else {
    //     this.searchConditions.resveMtValueCheckAll = false;
    //   }
    // },
    onSearchValueClear() {
      this.searchConditions.searchValue = null;
    },
    onSearchValueKeyDown(event) {
      if (event.key === "Enter") {
        this.getGroupInfoList();
      }
    },
    onSearchDetailPopupOpen() {
      this.isSearchDetailPopupOpen = true;
      this.$refs.searchDetailPopup.show();
    },
    onSearchDetailPopupClose() {
      this.$refs.searchDetailPopup.hide();
      this.isSearchDetailPopupOpen = false;
    },
    onViewButtonClicked() {
      this.getGroupInfoList();
    },
    onDialogSearchConditionInitClicked() {
      this.groupInfoOption.teamCnt = null;
      this.groupInfoOption.resveCycle = "";
      this.groupInfoOption.dwCode = "";
      this.groupInfoOption.grpHopeTime = "";
      this.groupInfoOption.areaCode = null;
      this.groupInfoOption.grpCharct = null;
      this.groupInfoOption.isDepositMoney = false;

      // this.groupInfoOption.grpKind = "";
      // this.groupInfoOption.areaCode = null;
      // this.searchConditions.useFlag = true;
      // this.searchConditions.isDepositMoney = false;
    //   this.searchConditions.resveMtValueCheckAll = true;
    //   this.searchConditions.resveMtList = [
    //     1,
    //     2,
    //     3,
    //     4,
    //     5,
    //     6,
    //     7,
    //     8,
    //     9,
    //     10,
    //     11,
    //     12,
    //   ];
    },
    onGroupCreateClicked() {
      this.openGroupRegistrationPopup();
    },
    onGroupRegistrationPopupClose({ refresh }) {
      if (refresh) {
        this.getGroupInfoList();
      }

      this.closeGroupRegistrationPopup();
    },
    async onGroupDeleteClicked() {
      try {
        let selectedRecords = this.$refs.groupManagementRegistration.getSelectedRecords();
        if (selectedRecords.length === 0) {
          this.errorToast("삭제할 데이터를 선택해 주세요");
          return;
        }
        if (!(await this.confirm("삭제하시겠습니까?"))) {
          return;
        }

        let grpNo = [];
        for (let i = 0; i < selectedRecords.length; i++) {
          grpNo.push(selectedRecords[i].grpNo);
        }

        await deleteGroupDetail(grpNo);
        this.getGroupInfoList();
      } catch (error) {
        console.error("deleteGroupInfo.err.===>", error);
      }
    },
    getGroupInfoList() {
      // this.groupInfoOption  상세
      // this.searchConditions 조회
      const searchValue =
          this.searchConditions.searchValue === ""
              ? null
              : this.searchConditions.searchValue;

      const grpKind =
          this.searchConditions.grpKind === ""
              ? null
              : this.searchConditions.grpKind;

      const grpDiv =
          this.searchConditions.grpDiv === ""
              ? null
              : this.searchConditions.grpDiv;

      const resveMm =
          this.searchConditions.resveMm === ""
              ? null
              : this.searchConditions.resveMm;

      const useFlag = this.searchConditions.useFlag;

      const teamCnt =
          this.groupInfoOption.teamCnt === ""
              ? null
              :this.groupInfoOption.teamCnt;

      const resveCycle =
          this.groupInfoOption.resveCycle === ""
              ? null
              :this.groupInfoOption.resveCycle;

      const dwCode =
          this.groupInfoOption.dwCode === ""
              ? null
              :this.groupInfoOption.dwCode;

      const grpHopeTime =
          this.groupInfoOption.grpHopeTime === ""
              ? null
              :this.groupInfoOption.grpHopeTime;

      const areaCode =
          this.groupInfoOption.areaCode === ""
              ? null
              : this.groupInfoOption.areaCode;

      const grpCharct =
          this.groupInfoOption.grpCharct === ""
              ? null
              : this.groupInfoOption.grpCharct;

      const isDepositMoney = this.groupInfoOption.isDepositMoney;

      // const resveMtTimeList =
      //   this.searchConditions.resveMtList.length === 0
      //     ? null
      //     : this.searchConditions.resveMtList.join();

      getGroupInfoList(searchValue, grpKind, grpDiv, resveMm, useFlag, teamCnt, resveCycle, dwCode, grpHopeTime, areaCode, grpCharct, isDepositMoney)
        .then((response) => {
          this.groupInfoList = response.value.groupList.map((item, index) => {
            item.grpKind = commonCodesGetComName("GRP_KIND", item.grpKind);
            item.paymtCode = commonCodesGetComName(
              "PAYMT_CODE",
              item.paymtCode
            );
            return {
              ...item,
              _rid: index + 1,
            };
          });

          this.groupInfoListLength = this.groupInfoList.length;
        })
        .catch((error) => {
          console.error("getGroupInfoList.err.===>", error);
        });
    },
    gridActionComplete() {
      this.count = numberWithCommas(
        this.$refs.groupManagementRegistration?.getGridBatchCount() || 0
      );
    },
    groupSmsSend() {
      const selectedRecords = this.$refs.groupManagementRegistration.getSelectedRecords();

      if (!(selectedRecords.length > 0)) {
        return this.errorToast("SMS 전송할 자료를 1건 이상 선택 바랍니다");
      }

      let smsSendInfo = {
        smsSendType: "NOTE",
        smsSendList: [],
      };

      selectedRecords.forEach((data) => {
        let sendData = {};
        if (data.generContactTel) {
          sendData.sendKey = data.grpNo;
          sendData.receiverName = data.generName,
          sendData.name =
            data.generName +
            (data.generMemberDiv
              ? commonCodesGetCommonCodeAttrbNameByCodeAndIdx(
                  "MEMBER_DIV",
                  data.generMemberDiv,
                  1
                )
              : "님");
          sendData.contactTel = data.generContactTel;
          smsSendInfo.smsSendList.push(JSON.parse(JSON.stringify(sendData)));
        }
      });

      if (smsSendInfo.smsSendList.length < 1) {
        return this.errorToast("연락처를 가진 회원이 없습니다");
      }

      this.isBatchSmsSendPopupOpen = true;
      this.$nextTick(() => {
        this.$refs.batchSmsSendPopup.showBatchSmsSendPopup(smsSendInfo);
      });
    },
    batchSmsSendPopupClosed() {
      this.isBatchSmsSendPopupOpen = false;
    },
    onClickExcel() {
      this.$refs.groupManagementRegistration.excelExport();
    },
  },
};
</script>
